@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.colors.scss";

.ContactPerson {
	@include coloredComponent;
	padding: var(--spacing--5xl) 0;
}

.ContactPerson_infoWrapper {
	@include mq("md") {
		display: flex;
		height: 100%;
		width: 66%;
	}
}

.ContactPerson_infoContainer {
	flex: 1 1;

	@include mq("md") {
		display: flex;
		flex-direction: column;
		gap: var(--spacing--4xl);
		justify-content: space-between;
	}

	@include mq("lg") {
		flex: auto;
	}
}

.ContactPerson_heading {
	padding-bottom: var(--spacing--base);

	@include mq("md") {
		padding-bottom: var(--spacing--lg);
	}
}

.ContactPerson_ctaButton {
	display: block;
	padding-bottom: var(--spacing--4xl);

	@include mq("md") {
		width: 60%;
		padding-bottom: 0;
	}
}

.ContactPerson_imageContainer {
	margin-right: 0;
	margin-left: auto;
}

.ContactPerson_picture {
	margin-top: var(--spacing--4xl);
	margin-bottom: var(--spacing--sm);
}

.ContactPerson_details {
	@include mq("md") {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 4rem;
	}

	@include mq("xl") {
		flex-wrap: nowrap;
	}
}

.ContactPerson_detailsColumn {
	padding-bottom: var(--spacing--sm);
	display: flex;
	justify-content: space-between;

	@include mq("md") {
		padding-bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}

// Theme: Kogp
[data-theme='kogp'] {
	.ContactPerson_detailsColumn {
		p:first-child {
			font-weight: var(--font-weight--extrablack);
		}
	}
}